import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import useSiteMetadata from "../hooks/sitemetadata"
import FlHdDiv from "../components/flhddiv"
import Row from "../components/row"
import BlueLink from "../components/buttons/bluelink"
import useHeaderLinks from "../hooks/headerlinks"
import useFooterLinks from "../hooks/footerlinks"
import MainCard from "../components/maincard"
import Abstract from "../components/abstract"
import AltView from "../components/altview"
import PageLayout from "../layouts/pagelayout"

type TemplateProps = {
  path: string
  pageContext: any
}

const SiteMapTemplate: React.FC<TemplateProps> = ({ path, pageContext }) => {
  const { title, crumbs, allFaculty, index } = pageContext

  const { description, paths } = useSiteMetadata()
  const headerLinks = useHeaderLinks()
  const footerLinks = useFooterLinks()
  const { sharedInstrumentLink } = useSiteMetadata()

  return (
    <PageLayout path={path} title={title} crumbs={crumbs} index={index}>
      <FlHdDiv>
        <Container>
          <AltView size="lg">
            <Abstract>
              <p className="h-full text-justify">{description}</p>
            </Abstract>

            <MainCard className="mb-8">
              <p className="text-justify lg:p-8">{description}</p>
            </MainCard>
          </AltView>

          <div className="mt-16 py-4 border-t border-solid border-gray-300 font-medium">
            <Row isVCentered={false} wrap={true}>
              <div className="w-full md:w-1/3">
                <h4 className="mt-4">About Us</h4>

                <div className="mt-2">
                  <BlueLink to="/about">About</BlueLink>
                </div>

                <div className="mt-2">
                  <BlueLink to="/help">Help</BlueLink>
                </div>

                <div className="mt-2">
                  <BlueLink to="/privacy">Privacy Policy</BlueLink>
                </div>
                <div className="mt-2">
                  <BlueLink to="/terms">Terms of Use</BlueLink>
                </div>

                <div className="mt-2">
                  <BlueLink to="/contact">Contact Us</BlueLink>
                </div>

                <div className="mt-2" key="booking">
                  <BlueLink
                    aria-label={`Goto ${sharedInstrumentLink.name}`}
                    to={sharedInstrumentLink.url}
                  >
                    {sharedInstrumentLink.name}
                  </BlueLink>
                </div>
              </div>

              <div className="w-full md:w-1/3">
                <h4 className="mt-4">Learn More</h4>
                {headerLinks.map((link: any, i: number) => {
                  return (
                    <div className="mt-2" key={i}>
                      <BlueLink aria-label={`Goto ${link.name}`} to={link.link}>
                        {link.name}
                      </BlueLink>
                    </div>
                  )
                })}
              </div>
            </Row>
          </div>

          <div className="py-4 mt-8 border-t border-solid border-gray-300 font-medium">
            <h3 className="my-4">Faculty</h3>

            <Row isVCentered={false} wrap={true}>
              <div className="w-full md:w-1/3 mb-8">
                <h4>Members</h4>

                {allFaculty
                  .filter((g: any) => {
                    return g.name.includes("Director") || g.name === "Members"
                  })
                  .map((g: any) => {
                    return g.people.map((gp: any) => {
                      return (
                        <div
                          key={`header-${gp.person.frontmatter.id}`}
                          className="mt-2"
                        >
                          <BlueLink
                            to={`${paths.facultyPath}/${gp.person.frontmatter.id}`}
                          >
                            {gp.person.frontmatter.name}
                          </BlueLink>
                        </div>
                      )
                    })
                  })}
              </div>
              <div className="w-full md:w-1/3">
                <h4>Associate Members</h4>
                {allFaculty
                  .filter((g: any) => {
                    return g.name.includes("Associate")
                  })
                  .map((g: any) => {
                    return g.people.map((gp: any) => {
                      return (
                        <div
                          key={`header-${gp.person.frontmatter.id}`}
                          className="mt-2"
                        >
                          <BlueLink
                            to={`${paths.facultyPath}/${gp.person.frontmatter.id}`}
                          >
                            {gp.person.frontmatter.name}
                          </BlueLink>
                        </div>
                      )
                    })
                  })}
              </div>
            </Row>
          </div>
        </Container>
      </FlHdDiv>
    </PageLayout>
  )
}

export default SiteMapTemplate

export const query = graphql`
  query {
    images: allFile(
      filter: {
        absolutePath: { regex: "/images/people/500x500/rounded/" }
        ext: { regex: "/png/" }
      }
    ) {
      edges {
        node {
          name
          ext
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 500, aspectRatio: 1)
          }
        }
      }
    }
  }
`

// allPublications: allPublicationsJson(filter: { person: { eq: $id } }) {
//   edges {
//     node {
//       person
//       publications {
//         pmid
//         pmcid
//         doi
//         isbn
//         title
//         abstract
//         authors
//         people
//         journal
//         issue
//         pages
//         volume
//         year
//         month
//         day
//         url
//         tags
//       }
//     }
//   }
// }
